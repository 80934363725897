.cta{
    margin: 0 $gap * -2 $gap * 2 $gap * -2;

    background: $pink;
    text-align: center;
    padding: $gap * 3;

    h3{
        color: $black;
        font-size: 1.5rem;
    }

    .button{
        background: $yellow;
        box-shadow: 0 1px 1px rgba(0,0,0,.3);
        font-style: italic;

        &:hover{
            background: $light-blue;
        }
    }

    @media only screen and (min-width: $tablet-portrait){
        margin: 0 0 $gap * 2 0;
    }
}