/**
* nav CSS file 
* Styling for the header:
* 
*/
.main-nav{
    
}

.mainmenu .container{
    display: flex;
    max-width: calc( #{$desktop-container} + #{$gap * 2 });
    align-items: center;
    height: 100%;

    .menu-switch{
        display: none;
    }

    .main-nav{
        margin-right: $gap * 2;
        margin-left: auto;

        ul{
            display: flex;
            flex-flow: row nowrap;
            padding: 0;
            margin: 0;
            list-style: none;

            ul{
                display: none;
            }
        }

        li{
            margin-left: $gap * 2;
            font-weight: 700;
            font-size: 1.3rem;
            text-decoration: none;
            position: relative;

            a{
                text-decoration: none;
                padding: 3px 6px;

                &:hover, &:focus{
                    color: $pink;
                }

                &:focus{
                    outline: 2px solid $black;
                }
            }
            
            &.current-menu-item a{
                text-decoration: underline;
            }

            &:hover ul{
                display: block;
                position: absolute;
                width: 300px;
                background: #fff;
                border-bottom: 2px solid $black;
                padding: 15px 0;
                z-index: 999;

                li{
                    margin: 0;
                    padding: 10px;
                }
            }
        }
    }
}

@media only screen and (max-width: $tablet-landscape){
    .mainmenu .container{

        .menu-switch{
            display: flex;
            flex-flow: column nowrap;
            margin: 0 15px 0 auto;
            border: 2px solid $black;
            background: none;
            padding-top: 3px;
            position: absolute;
            z-index: 990;
            right: 15px;
            i{
                width: 100%;
                height: 3px;
                background: $black;
                margin: 3px 0;
            }
        }

        .main-nav{
            width: 100vw;
            min-height: 100vh;
            background: white;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow-y: scroll;
            z-index: 890;
            display: none;


            ul{
                flex-flow: column nowrap;
                align-items: center;
                justify-content: center;
                height: 100%;

                li{
                    text-align: center;
                    margin-bottom: 10px;
                }
            }
        }
    }

    .mainmenu.active .container{
        .menu-switch{
            height: 57px;
            padding: 5px 6px;
            i:nth-child(1){
                transform: rotate( 45deg );
                margin: 10px 0 0 0;
            }
            i:nth-child(2){
                transform: rotate( -45deg );
                margin: 0 0 10px 0;
            }
            i:nth-child(3){
                display: none;
            }
            span{
                content: 'close';
            }
        }
        .main-nav{
            display: block;
        }
    }
}