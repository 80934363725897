.column{
    > h2, > h3, > h4{
        padding: 0 $gap * 2 $gap $gap * 2;
        display: block;
        font-size: 1.5rem;
        margin: $gap * 2 0 $gap * -2 0;
    }

    @media only screen and (min-width: $tablet-portrait ){
        > h2, > h3, > h4{
            padding: 0 0 $gap 0;
        }
    }
}