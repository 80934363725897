/**
* UI CSS file 
* Everything that enables user-interaction gets bootstrapped here
* 
*/

.button, .wp-block-button a{
	padding:$gap $gap * 5;
	background:$pink;
	display:inline-block;
	color:$black;
	text-decoration:none;
	line-height:100%;
    border-bottom: 2px solid rgba(0,0,0,.3);
    font-size: 1rem;
    font-weight: normal;
    font-style: italic;

	&:hover{
        background:$yellow;
        color: $black;
	}
}

.wp-block-button{
    display: block;
    text-align: center;
}


.btn, a[class^="btn--"]{
	@extend .button;
}


a, a:visited{
	color:#323232;
	text-decoration:underline;
}
