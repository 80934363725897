/**
* General CSS file 
* Styling for all general classes:
* 
*/

html, body{
	margin:0;
	padding:0;
	width:100%;
	height:100%;
}

body{
	background:$grey;
	font-size:16px;
	color:$font-color;
	font-family:$font-stack;
}

/**
 * firefox hack for flexbox
 */
*{
	min-height:0;
	min-width:0;
}

article{
    max-width: $desktop-container;
}

.columns{
    display: grid;
    grid-template-columns: 1fr;

    .column{
        margin-top: $gap * 2;
        padding: 0 $gap * 2;
    }

    @media only screen and ( min-width: $tablet-portrait ){
        grid-template-columns: repeat( 2, 1fr );
        grid-gap: $gap * 2;

        .column-1, .column-3{
            padding-right: 0;
            padding-left: $gap * 2;
        }

        .column-2{
            padding-left: 0;
            padding-right: $gap * 2;
        }
    }

    @media only screen and ( min-width: $desktop ){
        grid-template-columns: repeat( 3, 1fr );
        padding-left: $gap * 2;
        margin-right: $gap * 2;
        .column{
            padding: 0;
        }

        &.page-home{
            grid-template-columns: 1.5fr 1fr 1fr;
        }
    }

}