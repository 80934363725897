.socials{
    list-style: none;
    padding: 0;
    display: flex;
    flex-flow: row wrap;

    li{
        margin: 0 $gap $gap 0;

        a{
            color: $blue;

            &:hover{
                color: $red;
            }
        }
        svg{
            width: $gap * 3.5;
            height: $gap * 3.5;
        }
    }
}