/**
* Forms CSS file 
* Styling for forms:
* 
*/

 .gform_fields{
    list-style: none;
    padding: 0;
    margin: 0;

    li{
        margin-bottom: 15px;
    }


    textarea, input{
        box-sizing: border-box;
        width: 100%;
        max-width: 100%;
        padding: 5px;
        border: 1px solid #444;
    }
}

.gform_body, .gform_footer{
    background: $white;
    padding: $gap * 1.5;
    margin-top: $gap * 2;
}

.gform_footer{
    margin-top: 0;
    text-align: right;
    padding-top: 0;
}
