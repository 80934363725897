/**
* Variable file 
* place global variables here:
* 
*/


$font-stack:"covik-sans", sans-serif;
$head-stack:"grad", serif;

$black:#0E2834;
$white:#fff;
$grey: #FAFAFA;

$bgcolor:#f8f8f8;
$font-color:#46484a;

$pink: #F299C1;
$yellow: #FECB37;
$light-blue: #9EC9ED;
$blue: #2A4B9B;
$dark-blue: #152733;
$red: #E83B3B;
$green: #3A8A3D;
$orange: #E1542A;

$primaryColor:#e3c28f;
$secondaryColor:#739b75;
$tertiaryColor:#46484a;


//Sizes:
$gap: 10px;
$desktop-container: 1260px;
$container: 1024px;

$desktop: $container;
$tablet-landscape: 1024px;
$tablet-portrait: 768px;
$phone-landscape: 440px;
$phone-portrait: 320px;
