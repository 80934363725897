/**
* Main CSS file 
* this is where all your imports go.
* 
*/

//normalize
@import 'vendors/normalize';

//Custom variables:
@import 'variables';

//Core styling:
@import 'base/mixins';
@import 'base/a11y';
@import 'base/forms';
@import 'base/grid';
@import 'base/typography';
@import 'base/textual';
@import 'base/ui';

//all plugin styles:
@import 'vendors/plugins';

// Templates:
//@import 'layout/footer';
@import 'layout/general';
@import 'layout/header';
@import 'layout/nav';
@import 'layout/footer';

//Components:
@import 'components/heading';
@import 'components/intro';
@import 'components/figure';
@import 'components/bricks';

//Blocks:
@import 'blocks/card';
@import 'blocks/collection';
@import 'blocks/main-block';
@import 'blocks/artist';
@import 'blocks/embeds';
@import 'blocks/call-to-action';
@import 'blocks/partners';
@import 'blocks/socials';