.card{
    background: $white;
    padding: $gap * 2 $gap * 2 $gap * 5 $gap * 2;
    border-top: 4px solid $black;
    margin: $gap * 2 0;
    position: relative;

    h3{
        font-size: 1.1rem;
        font-style: italic;
        font-weight: 600;
    }

    img{
        max-width: 100%;
        height: auto;
    }

    time{
        font-size: .9rem;
        font-style: italic;
        display: block;
        text-align: right;
        font-weight: 600;
    }

    a{
        text-decoration: none;
    }

    .button{
        position: absolute;
        bottom: 0;
        right: 0;
        background: transparent;
        border: 0;
        color: $red;
        text-decoration: underline;
        padding: 3px 6px;
        margin: $gap * 1.5;
        margin-left: 0;
    }

    &--news{
        color: $blue;
        .button:hover{ background: $yellow; color: $black; }
    }

    &--project{
        .button:hover{ background: $yellow; color: $black; }
    }

    @media only screen and ( min-width: $tablet-portrait ){
        margin: $gap * 2 0;
    }
}