.collection{

    .pages{
        display: block;
        width: 100%;
        text-align: center;
        padding-bottom: $gap;

        a, span{
            background: $grey;
            padding: 5px 10px;
            text-align: center;
            font-size: 1.1rem;
            margin-right: 5px;
            text-decoration: none;

            &:hover{
                cursor: pointer;
                background: $pink;
            }
        }

        span{
            background: $yellow;
        }
    }

    &.grid{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: $gap;
        background: $white;
        padding: $gap;
        border-top: 4px solid $orange;
        margin: $gap * 2 0 ;

        .card{
            border-top: 0;
            padding: 0 20px;
            img{
                width: 100%;
            }

            &--post strong{ color: $blue; }
            &--project strong{ color: $red; }

        }


        .pages{
            grid-column: 1 / span 2;
        }
    }
}

.acf-block-preview .collection.grid{
    .card{
        text-align: center;
        max-width: 190px;
        border-top: 2px solid #eee;
        img{
            width: 100%;
            display: block;
        }
    }
}