figure, .wp-block-image{
    margin: 0;
    
    img, iframe{
        border: 3px solid $black;
        border-left: 0;
        border-right: 0;
        display: block;
        max-width: 100%;
    }

    iframe{
        margin: 0;
    }

    figcaption{
        float: right;
        background: rgba(0,0,0,.1);
        padding: 3px 8px;
        color: $black !important;
        margin: 0 0 $gap * 2 0 !important;
        font-size: .8rem;
    }

    &.wp-block-audio{
        display: flex;
        flex-flow: row wrap;
        border-top: 2px solid $black;
        margin-bottom: 15px;
        audio{
            flex: 1 0 100%;
            background: #F2F3F4;
        }
    }
}