.bricks{
    display: grid;
    grid-template-columns: 1fr 1fr;

    > *{ grid-column: 1 / span 2; }
    .brick{ 
        grid-column: span 1;
        text-decoration: none;
        font-weight: 400;
        font-style: italic;
        img{
            width: 100%;
            height: auto;
        }

        p{
            margin: 0 0 5px 5px;
        }
    }

    a.brick:hover, a.brick:focus{
        outline: 4px solid $orange;
        p{
            color: $orange;
            text-decoration: underline;
        }

        img{
            opacity: .8;
        }
    }
}