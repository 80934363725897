/**
* Header CSS file 
* Styling for the header:
* 
*/

header{
    width: 100%;
    height: 100px;
    background: $white;

    .logo{
        height: 200px;
        width: 200px;
        position: absolute;
        top: $gap * 3;
        left: $gap * 5;
        z-index: 100;

        img{
            max-height: 100%;
            width: auto;
        }
    }
}