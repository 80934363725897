/**
* Footer CSS file 
* Styling for the footer:
* 
*/

.footer{
    background: $white;
    padding: 50px 0;

    .copyright{
        max-width: $container;
        text-align: center;
    }

    .columns{
        max-width: $desktop-container;
        padding: 0 0 0 $gap * 3;
        margin: 0;

        .column{
            margin: 0;

            h2, h3, h4{
                margin: 0;
            }
        }
    }
}