.page-intro{
    background: $yellow;
    padding: $gap * 15 $gap * 5 $gap * 2.5 $gap * 5;
    margin: $gap * -2; 
    margin-bottom: $gap * 2;

    h1.hidden{
        display: none;
    }

    h1, h2, h3, h4, p, strong, li, span, i, strong{
        color: $dark-blue;
    }

    address{
        margin-top: $gap * 2;
        padding: $gap;
        background: lighten( $yellow, 10% );
        font-size: .9rem;
        p{
            margin: 0;
        }

        a{
            padding-top: 1.1rem;
        }

        a, span{
            display: block;
        }
    }

    @media only screen and (min-width: $tablet-portrait){
        margin: 0 0 $gap * 2 $gap * -2;
    }
}
