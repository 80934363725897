.artist-block{
    border-top: 5px solid $pink;
    display: flex;
    flex-flow: row wrap;
    

    .title, .photo{
        flex: 1 0 40%;
        font-family: sans-serif;
    }

    .title h3{
        margin: 0 0 $gap 0;
        font-size: 1.3rem;
    }
    .title strong{
        font-size: 1.1rem;
        font-weight: 400;
        font-style: normal;
    }

    .caption{
        font-size: .8rem;
        color: #999;
        font-style: italic;
    }

    .photo{
        margin: 0 0 $gap 0;
        img{
            max-width: 100%;
            height: auto;
        }
    }

    .biography{
        font-size: 1rem;
        flex: 1 0 100%;
    }
}